import { format } from 'date-fns'
import { IconWeight } from '@phosphor-icons/react'
import { Activity } from 'store/modules/activities'
import { activityIconMap } from '../icon-map'
import { formatDate } from 'app/packs/src/utils/date-helpers'
import { User } from 'store/modules/users'

export abstract class ActivityItemVm<Item extends Activity = Activity> {
  constructor(protected activity: Item) {}

  private readonly date = new Date(this.activity.createdAt)

  receivers: User[] = []
  type = this.activity.recordType

  get creators() {
    return [this.activity.creator]
  }

  get dateTime() {
    return format(this.date, 'yyyy-MM-dd')
  }

  get formattedDate(): string {
    return formatDate(this.date)
  }

  get icon() {
    return activityIconMap[this.type]
  }

  get iconWeight(): IconWeight {
    return 'bold'
  }

  get title(): string {
    return this.type
  }

  get visibility() {
    switch (this.activity.recordType) {
      case 'Action':
      case 'Checkin':
      case 'FeedbackItem':
      case 'PositionChange':
      case 'SkillsUser':
        return 'reporting_line'
      case 'Win':
        return this.activity.record.visibility
    }
  }

  get visibilityText() {
    switch (this.visibility) {
      case 'only_me':
        return 'Only you'
      case 'reporting_line':
        return 'You and your reporting line'
      case 'org':
        return 'Everyone in your org'
    }
  }

  get showReceivers() {
    return (
      this.receivers.filter(
        (user) => !this.creators.map((creator) => creator.id).includes(user.id)
      ).length > 0
    )
  }
}
