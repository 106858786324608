import { Card } from 'src/design-system'
import * as React from 'react'
import { ActionCompletedActivity } from '../../../store/modules/activities'
import { ActionActivityItemVm } from '../activity-item-vm'
import { ActivityFeedHeader } from '../activity-feed-header'
import { SkillTags } from '../../skill-tags'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { ActionOverflow } from 'components/actions-table/action-overflow'
import { store } from 'store/index'

export type ActionActivityItemProps = {
  activity: ActionCompletedActivity
  focusSkillIds: string[]
}

export const ActionActivityItem: React.VFC<ActionActivityItemProps> = (
  props
) => {
  const { activity, focusSkillIds } = props

  const vm = new ActionActivityItemVm(activity)

  if (!store.currentUser?.id) return null

  return (
    <div className="flex w-full flex-col pb-10">
      <ActivityFeedHeader vm={vm} />
      <div className="ml-8">
        <Card className="m-0">
          <HtmlContent className="mb-2">{vm.content}</HtmlContent>
          <div className="flex flex-row justify-between flex-grow">
            <div>
              <SkillTags
                focusSkillIds={focusSkillIds}
                showLinks
                skills={vm.skills}
              />
            </div>
            <div className="flex justify-end">
              <ActionOverflow action={activity.record} source="activity_feed" />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
