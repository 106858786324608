import { Button, Card, Link, useModalContext } from 'src/design-system'
import * as React from 'react'
import pluralize from 'pluralize'
import { ActivityFeedHeader } from '../activity-feed-header'
import { FeedbackRequestActivityItemVm } from '../activity-item-vm'
import { FeedbackRequestedActivity } from '../../../store/modules/activities'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { CreateFormProps } from 'components/create-form'
import { store } from 'store/index'
import { FormattedDateTime } from 'components/formatted-datetime'

export type FeedbackRequestActivityItemProps = {
  activity: FeedbackRequestedActivity
}

export const FeedbackRequestActivityItem: React.VFC<
  FeedbackRequestActivityItemProps
> = (props) => {
  const { activity } = props

  const { openModal } = useModalContext()

  const vm = new FeedbackRequestActivityItemVm(activity)

  const onClickGiveFeedback = React.useCallback(() => {
    openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
      resourceId: vm.feedbackRequest.id,
      resourceType: 'FeedbackRequest',
      user: store.nonNullCurrentUser,
      initialUserIds: [vm.creator.id],
      source: 'feedback-request-activity-item',
      tabs: ['feedback'],
    })
  }, [openModal, vm.creator, vm.feedbackRequest.id])

  const feedbackItemResponse = vm.feedbackItemAuthoredBy(
    store.nonNullCurrentUser.id
  )

  return (
    <div className="flex w-full flex-col pb-10">
      <ActivityFeedHeader vm={vm} />
      <div className="ml-8">
        <Card className="m-0">
          <HtmlContent className="mb-2">{vm.content}</HtmlContent>
          {!vm.userIsRequester && !feedbackItemResponse && (
            <Button onClick={onClickGiveFeedback}>Give Feedback</Button>
          )}
          {!vm.userIsRequester && feedbackItemResponse && (
            <div className="flex flex-row gap-1 text-xs text-gray-600">
              Responded
              <FormattedDateTime
                className="text-gray-600 text-xs"
                date={feedbackItemResponse.createdAt}
              />
            </div>
          )}
          {vm.userIsRequester && (
            <div className="flex flex-row gap-2 text-xs text-gray-600">
              {vm.feedbackItems.length > 0 && (
                <div>
                  {pluralize('response', vm.feedbackItems.length, true)}
                </div>
              )}
              {vm.feedbackItems.length > 0 && vm.awaitingResponseCount > 0 && (
                <span>&middot;</span>
              )}
              {vm.awaitingResponseCount > 0 && (
                <div>
                  Awaiting{' '}
                  {pluralize('response', vm.awaitingResponseCount, true)}
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}
