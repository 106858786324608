import * as React from 'react'
import { ActivityItemVm } from '../activity-item-vm/activity-item-vm'

export type ActivityFeedItemTypeProps<VM> = {
  vm: VM
}

export const ActivityFeedItemType = <VM extends ActivityItemVm>(
  props: ActivityFeedItemTypeProps<VM>
) => {
  const { vm } = props

  return (
    <div className="flex items-center gap-1.5 text-gray-600">
      <span className="mb-px">{vm.title}</span>
      <vm.icon aria-hidden className="w-4 h-4" weight={vm.iconWeight} />
    </div>
  )
}
