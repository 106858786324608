import React from 'react'
import { ReactionBarVm } from './reaction-bar-vm'
import { ReactionItem } from './reaction'

export type ReactionsProps = {
  vm: ReactionBarVm
}

export const Reactions: React.VFC<ReactionsProps> = (props) => {
  const { vm } = props

  if (vm.uniqueReactions.length === 0) return null

  return (
    <>
      {vm.uniqueReactions.map((reaction) => (
        <ReactionItem reaction={reaction.content} vm={vm} key={reaction.id} />
      ))}
    </>
  )
}
