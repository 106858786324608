import {
  Checks,
  CircleWavyCheck,
  Clipboard,
  Megaphone,
  Note,
  Rocket,
  Star,
} from '@phosphor-icons/react'

export const activityIconMap = {
  Action: Checks,
  Checkin: Clipboard,
  FeedbackItem: Megaphone,
  FeedbackRequest: Megaphone,
  Note: Note,
  PositionChange: Rocket,
  SkillsUser: Star,
  Win: CircleWavyCheck,
}
