import * as React from 'react'
import { ActivityFeedHeader } from '../activity-feed-header'
import { CheckinActivity } from '../../../store/modules/activities'
import { CheckinActivityItemVm } from '../activity-item-vm/checkin-activity-item-vm'
import { WithAnalytics } from 'components/with-analytics'

export type CheckinActivityItemProps = {
  activity: CheckinActivity
}

export const CheckinActivityItem: React.VFC<CheckinActivityItemProps> = (
  props
) => {
  const { activity } = props

  const vm = new CheckinActivityItemVm(activity)

  return (
    <div className="flex flex-col pb-6 w-full">
      <ActivityFeedHeader vm={vm} />
      {vm.showLink && (
        <div className="ml-[60px]">
          <WithAnalytics
            event={'$track_checkin_activity_item_link_clicked'}
            params={{ button: vm.linkText }}
          >
            <a href={vm.link} className="text-theme-40">
              {vm.linkText}
            </a>
          </WithAnalytics>
        </div>
      )}
    </div>
  )
}
