import { Card, Link } from 'src/design-system'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { WinOverflowMenu } from 'components/win-overflow-menu'
import * as React from 'react'
import { store } from 'store/index'
import { WinCreatedActivity } from '../../../store/modules/activities'
import { ReactionBar } from '../../reaction-bar'
import { SkillTags } from '../../skill-tags'
import { ActivityFeedHeader } from '../activity-feed-header'
import { WinActivityItemVm } from '../activity-item-vm'
import { can } from 'app/packs/src/policies'

export type WinActivityItemProps = {
  activity: WinCreatedActivity
  disableLink?: boolean
  focusSkillIds: string[]
}

export const WinActivityItem: React.VFC<WinActivityItemProps> = (props) => {
  const { activity, disableLink, focusSkillIds } = props

  const { currentUser } = store
  const vm = new WinActivityItemVm(activity)

  const content = (
    <HtmlContent className="text-gray-900">{vm.content}</HtmlContent>
  )

  if (!currentUser) return null
  return (
    <div className="flex w-full flex-col pb-10">
      <ActivityFeedHeader vm={vm} />
      <div className="ml-8">
        <Card className="m-0 gap-2">
          {activity.record.winCategory?.title === 'Win' && !disableLink ? (
            <Link
              className="p-0"
              data-remote={true}
              href={`wins/${activity.record.id}`}
              underline={false}
            >
              {content}
            </Link>
          ) : (
            content
          )}
          <SkillTags
            focusSkillIds={focusSkillIds}
            showLinks
            skills={vm.skills}
          />
          <div className="flex flex-row justify-between">
            <ReactionBar reactableId={vm.recordId} reactableType="Win" />
            <WinOverflowMenu
              winId={vm.recordId}
              winCategory={activity.record.winCategory?.title || 'Win'}
              allowDelete={can(currentUser, activity.record).delete}
              allowEdit={can(currentUser, activity.record).edit}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}
