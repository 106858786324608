import { Card } from 'src/design-system'
import * as React from 'react'
import { ActivityFeedHeader } from '../activity-feed-header'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { PositionChangeActivity } from '../../../store/modules/activities'
import { PositionChangeActivityItemVm } from '../activity-item-vm/position-change-activity-item-vm'

export type PositionChangeActivityItemProps = {
  activity: PositionChangeActivity
}

export const PositionChangeActivityItem: React.VFC<
  PositionChangeActivityItemProps
> = (props) => {
  const { activity } = props

  const vm = new PositionChangeActivityItemVm(activity)

  return (
    <div className="flex flex-col pb-10 w-full">
      <ActivityFeedHeader vm={vm} />
      <div className="ml-8">
        <Card className="m-0">
          <HtmlContent className="mb-2">{vm.content}</HtmlContent>
        </Card>
      </div>
    </div>
  )
}
