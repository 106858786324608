import { PositionChangeActivity } from 'store/modules/activities'
import { ActivityItemVm } from './activity-item-vm'

export class PositionChangeActivityItemVm extends ActivityItemVm<PositionChangeActivity> {
  content = `Promoted from ${this.activity.record.positionFromName} to ${this.activity.record.positionToName} by ${this.activity.creator.fullName}. Congratulations! 🎉`

  get creators() {
    return [this.activity.record.user]
  }

  get title() {
    return 'Promotion'
  }
}
