import { Tooltip } from 'src/design-system'
import * as Popover from '@radix-ui/react-popover'
import { Smiley } from '@phosphor-icons/react'
import * as React from 'react'
import { ReactionBarVm } from './reaction-bar-vm'

export type ReactionPickerProps = {
  vm: ReactionBarVm
}

export const ReactionPicker: React.VFC<ReactionPickerProps> = (props) => {
  const { vm } = props

  return (
    <Popover.Root>
      <div
        className="min-h-[1.625rem] flex items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <Popover.Trigger asChild>
          <button className="flex items-center">
            <Tooltip content={vm.addReactionTooltipMessage}>
              <Smiley className="w-4 h-4 text-gray-600" weight="bold" />
            </Tooltip>
          </button>
        </Popover.Trigger>
      </div>
      <Popover.Content align="start" sideOffset={8}>
        <div className="z-[5000] bg-white rounded-lg p-1 flex flex-row items-center gap-x-1 border border-gray-200">
          {vm.defaultReactions.map((reaction) => (
            <Popover.Close asChild key={reaction}>
              <button
                className="text-xl p-1 rounded-md transition hover:bg-gray-50"
                type="button"
                onClick={() => vm.onClickReaction(reaction)}
              >
                {reaction}
              </button>
            </Popover.Close>
          ))}
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}
