import * as React from 'react'
import {
  ActionActivityItem,
  CheckinActivityItem,
  FeedbackActivityItem,
  FeedbackRequestActivityItem,
  FocusSkillActivityItem,
  PositionChangeActivityItem,
  WinActivityItem,
} from './activity-feed-item'
import { Activity } from '../../store/modules/activities'

export type ActivityItemsListProps = {
  activities: Activity[]
  disableLink?: boolean
  focusSkillIds: string[]
  showFocusSkillName?: boolean
}

export const ActivityItemsList: React.VFC<ActivityItemsListProps> = (props) => {
  const {
    activities,
    disableLink,
    focusSkillIds,
    showFocusSkillName = false,
  } = props

  if (activities.length === 0) return null

  return (
    <div className="pl-4 relative">
      <div className="absolute left-4 ml-[-0.5px] top-0 h-full w-px bg-gray-100" />
      {activities.map((activity) => {
        switch (activity.recordType) {
          case 'Action':
            return (
              <ActionActivityItem
                activity={activity}
                focusSkillIds={focusSkillIds}
                key={activity.id}
              />
            )
          case 'Checkin':
            return <CheckinActivityItem activity={activity} key={activity.id} />
          case 'FeedbackItem':
            return (
              <FeedbackActivityItem activity={activity} key={activity.id} />
            )
          case 'FeedbackRequest':
            return (
              <FeedbackRequestActivityItem
                activity={activity}
                key={activity.id}
              />
            )
          case 'PositionChange':
            return (
              <PositionChangeActivityItem
                activity={activity}
                key={activity.id}
              />
            )
          case 'SkillsUser':
            return (
              <FocusSkillActivityItem
                activity={activity}
                key={activity.id}
                showSkillName={showFocusSkillName}
              />
            )
          case 'Win':
            return (
              <WinActivityItem
                activity={activity}
                disableLink={disableLink}
                focusSkillIds={focusSkillIds}
                key={activity.id}
              />
            )
        }
      })}
    </div>
  )
}
