import * as React from 'react'
import { ReactionBarVm } from './reaction-bar-vm'
import { useStore } from 'store/context'
import { Reaction } from 'store/modules/reactions'

type UseReactionBarVmProps = {
  reactableId: string
  reactableType: string
  reactions: Reaction[]
}

export const useReactionBarVm = (props: UseReactionBarVmProps) => {
  const { reactableId, reactions, reactableType } = props

  const { nonNullCurrentUser: currentUser } = useStore()

  const vm = React.useMemo(() => {
    return new ReactionBarVm(reactions, reactableId, reactableType, currentUser)
  }, [reactableId, reactableType, reactions, currentUser])

  return vm
}
