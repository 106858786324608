import { ArrowRight } from '@phosphor-icons/react'
import { AvatarButtonGroup } from 'src/design-system'
import * as React from 'react'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { User } from 'store/modules/users'
import cn from 'classnames'

export type ActivityFeedAvatarsProps = {
  creators: User[]
  receivers: User[]
  showReceivers: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const ActivityFeedAvatars: React.VFC<ActivityFeedAvatarsProps> = (
  props
) => {
  const { creators, receivers, showReceivers, size } = props

  const arrowSize = size === 'xs' ? 'h-3 w-3' : 'h-4 w-4'

  return (
    <div className="flex flex-row items-center">
      <AvatarButtonGroup
        avatars={avatarGroupProps(creators, { href: true, stateTooltip: true })}
        className="mr-1"
        size={size || 'sm'}
      />
      {showReceivers && (
        <>
          <ArrowRight
            aria-hidden
            weight="bold"
            className={cn('text-theme-50 mx-1', arrowSize)}
          />
          <span className="sr-only">Given to</span>
          <AvatarButtonGroup
            avatars={avatarGroupProps(receivers, {
              href: true,
              stateTooltip: true,
            })}
            size={size || 'sm'}
            limit={5}
          />
        </>
      )}
    </div>
  )
}
