import { Tab } from 'components/create-form'
import { CreateFormModalButton } from 'components/create-form-modal'
import { Plus } from '@phosphor-icons/react'
import * as React from 'react'
import { store } from 'store/index'

type AddItemButtonProps = {
  title?: string
  activeTab?: Tab
}

export const AddItemButton: React.FC<AddItemButtonProps> = ({
  title = 'Add update',
  activeTab,
}) => {
  const { currentUser } = store
  if (!currentUser) return null

  return (
    <div className="pb-10 relative pl-4">
      <div className="absolute left-4 ml-[-0.5px] top-7 h-full pb-6 w-px bg-gray-100"></div>
      <div className="-ml-4 w-full flex flex-row gap-4">
        <div className="mt-1 ml-1">
          <div className="rounded-full w-6 h-6 bg-theme-40 flex items-center justify-center">
            <Plus className="text-white" weight={'bold'} />
          </div>
        </div>

        <CreateFormModalButton
          title={title}
          user={currentUser}
          source={'activity_feed'}
          className="btn btn-brand btn-sm "
          label={title}
          initialTabId={activeTab}
        />
      </div>
    </div>
  )
}
