import { IconWeight } from '@phosphor-icons/react'
import { ActivityItemVm } from './activity-item-vm'
import { FocusSkillActivity } from 'store/modules/activities'

export class FocusSkillActivityItemVm extends ActivityItemVm<FocusSkillActivity> {
  constructor(activity: FocusSkillActivity, private showSkillName: boolean) {
    super(activity)
  }

  get iconWeight(): IconWeight {
    switch (this.activity.action) {
      case 'create':
        return 'fill'
      default:
        return 'bold'
    }
  }

  get title() {
    const actionText =
      this.activity.action === 'create' ? 'Focused' : 'Unfocused'

    const skillText = this.showSkillName
      ? this.activity.record.name
      : 'this skill'

    return `${actionText} ${skillText}`
  }
}
