import { store } from 'store/index'
import { FeedbackReceivedActivity } from 'store/modules/activities'
import { SkillSelectProps } from '../../skill-select/skill-select.types'
import { ActivityItemVm } from './activity-item-vm'

export class FeedbackActivityItemVm extends ActivityItemVm<FeedbackReceivedActivity> {
  content = this.activity.record.content
  receivers = [this.activity.record.receiver]

  get title() {
    return this.allowUpdateSkills ? 'Feedback' : 'Feedback Given'
  }

  get feedbackItem() {
    return this.activity.record
  }

  get allowUpdateSkills() {
    return store.currentUser?.id === this.feedbackItem.receiver.id
  }

  get skillSelectProps() {
    return {
      skills: this.feedbackItem.receiver.sortedSkills(),
      defaultSkillIds: this.feedbackItem.skills.map((skill) => skill.id),
      focusSkillIds: this.focusSkillIds,
      onChange: (skillIds) => this.updateSkills(skillIds),
      triggerProps: {
        showSelected: false,
        showIcons: false,
        showStar: false,
        className: 'flex-shrink-0',
        size: 'sm',
      },
    } satisfies SkillSelectProps
  }

  updateSkills(skills: string[]) {
    store.feedbackItems.update(this.feedbackItem.id, { skills })
  }

  get focusSkillIds() {
    return store.userSkills.focusedSkillIdsForUser(
      this.feedbackItem.receiver.id
    )
  }

  get anySkills() {
    return this.feedbackItem.skills.length > 0
  }

  get skillSelectLabel() {
    return this.anySkills ? 'Edit Skills' : 'Add Skills'
  }
}
