import * as React from 'react'
import cn from 'classnames'
import { ReactionPicker } from './reaction-picker'
import { Reactions } from './reactions'
import { useReactionBarVm } from './use-reaction-bar-vm'
import { observer } from 'mobx-react-lite'
import { store } from 'store/index'

export type ReactionBarProps = {
  reactableId: string
  reactableType: string
  className?: string
}

export const ReactionBar = observer((props: ReactionBarProps) => {
  const { reactableId, reactableType, className } = props
  const { reactions } = store

  const recordReactions = reactions.forReactable(reactableId, reactableType)

  const vm = useReactionBarVm({
    reactableId,
    reactableType,
    reactions: recordReactions,
  })

  if (!vm) return null

  return (
    <div
      className={cn(
        'flex flex-row items-center gap-x-2 gap-y-1 flex-wrap',
        className
      )}
    >
      <Reactions vm={vm} />
      <ReactionPicker vm={vm} />
    </div>
  )
})
