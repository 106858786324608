import * as React from 'react'
import cn from 'classnames'
import { ActivityFeedAvatars } from './activity-feed-avatars'
import { ActivityItemVm } from '../activity-item-vm/activity-item-vm'
import { ActivityFeedItemType } from './activity-feed-item-type'
import { ActivityFeedHeaderSeparator } from './activity-feed-header-separator'
import { VisibilityIcon } from 'components/visibility-icon'
import { Tooltip } from 'src/design-system'

export type ActivityFeedHeaderProps<VM> = {
  vm: VM
}

export const ActivityFeedHeader = <VM extends ActivityItemVm>(
  props: ActivityFeedHeaderProps<VM>
) => {
  const { vm } = props

  return (
    <div
      className={cn(
        'flex w-full mb-3 -ml-4 gap-x-4 text-gray-600',
        !vm.showReceivers && 'flex-row items-center',
        vm.showReceivers && 'flex-col sm:flex-row sm:items-center'
      )}
    >
      <ActivityFeedAvatars
        creators={vm.creators}
        receivers={vm.receivers}
        showReceivers={vm.showReceivers}
      />
      <div
        className={cn(
          'flex flex-row flex-wrap',
          vm.showReceivers && 'ml-12 mt-3 sm:m-0'
        )}
      >
        <ActivityFeedItemType vm={vm} />
        <ActivityFeedHeaderSeparator />
        <time dateTime={vm.dateTime}>{vm.formattedDate}</time>
        {vm.visibility && (
          <>
            <ActivityFeedHeaderSeparator />
            <Tooltip content={vm.visibilityText}>
              <VisibilityIcon visibility={vm.visibility} includeTag={false} />
            </Tooltip>
          </>
        )}
      </div>
    </div>
  )
}
