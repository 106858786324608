import { Button, PropsWithoutChildren } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { Activity } from 'store/modules/activities'
import { ActivityItemsList } from './activity-items-list'
import { CreateFormModalButton } from 'components/create-form-modal'
import { AddItemButton } from './add-item-button'
import { Tab } from 'components/create-form'
import { Loader } from 'src/design-system'

export type ActivityFeedProps = {
  activities: Activity[]
  disableLink?: boolean
  focusSkillIds: string[]
  hasNextPage: boolean
  onLoadMoreClick?: React.MouseEventHandler<HTMLButtonElement>
  showFocusSkillName: boolean
  showAddButton?: boolean
  addItemTitle?: string
  addItemActiveTab?: Tab
} & PropsWithoutChildren<React.HTMLAttributes<HTMLDivElement>>

export const ActivityFeed = observer((props: ActivityFeedProps) => {
  const {
    activities,
    disableLink,
    focusSkillIds,
    hasNextPage,
    onLoadMoreClick,
    showFocusSkillName,
    className,
    addItemActiveTab,
    addItemTitle,
    showAddButton = true,
    ...restProps
  } = props

  const [loadMoreLoading, setLoadMoreLoading] = React.useState(false) // New state variable

  const handleLoadMoreClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (onLoadMoreClick) {
      setLoadMoreLoading(true) // Set loading state to true
      await onLoadMoreClick(event) // Call the original onLoadMoreClick
      setLoadMoreLoading(false) // Set loading state to false after loading is done
    }
  }

  return (
    <section
      className={cn('activity-feed mb-8 relative', className)}
      {...restProps}
    >
      {showAddButton && (
        <AddItemButton title={addItemTitle} activeTab={addItemActiveTab} />
      )}
      <ActivityItemsList
        activities={activities}
        disableLink={disableLink}
        focusSkillIds={focusSkillIds}
        showFocusSkillName={showFocusSkillName}
      />
      {hasNextPage &&
        (loadMoreLoading ? ( // Show loader while loading
          <Loader />
        ) : (
          <Button
            variant="outline"
            colourVariant="theme"
            size="default"
            onClick={handleLoadMoreClick} // Use new click handler
            className="m-auto"
          >
            Load more
          </Button>
        ))}
      {!hasNextPage && (
        <div
          className={cn(
            'flex flex-row items-center gap-x-2 ml-2.5',
            activities.length == 0 && 'absolute -bottom-9'
          )}
        >
          <span className="bg-gray-600 w-3 h-3 rounded-full flex-shrink-0" />
          <p className="mb-0 text-gray-600">Beginning of updates</p>
        </div>
      )}
    </section>
  )
})
