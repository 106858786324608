import * as React from 'react'
import { ActivityFeedHeader } from '../activity-feed-header'
import { FocusSkillActivity } from '../../../store/modules/activities'
import { FocusSkillActivityItemVm } from '../activity-item-vm/focus-skill-activity-item-vm'

export type FocusSkillActivityItemProps = {
  activity: FocusSkillActivity
  showSkillName?: boolean
}

export const FocusSkillActivityItem: React.VFC<FocusSkillActivityItemProps> = (
  props
) => {
  const { activity, showSkillName = false } = props

  const vm = new FocusSkillActivityItemVm(activity, showSkillName)

  return (
    <div className="flex w-full flex-col pb-6">
      <ActivityFeedHeader vm={vm} />
    </div>
  )
}
