import { ActivityItemVm } from './activity-item-vm'
import { CheckinActivity } from 'store/modules/activities'
import { store } from 'store/index'

export class CheckinActivityItemVm extends ActivityItemVm<CheckinActivity> {
  get creators() {
    return this.activity.record.users
  }

  get title() {
    const positionName = this.activity.record.positionName
    const positionText = positionName ? ` for ${positionName}` : ''

    if (this.activity.finalised) {
      return `Completed joint review of Check-in${positionText}`
    } else if (this.activity.assessorApproved) {
      return `Completed review of ${this.name} Check-in${positionText}`
    } else {
      return `Check-in: Completed step 1${
        positionName ? ` against ${positionName}` : ''
      }`
    }
  }

  get viewerIsAuthor() {
    return this.activity.record.author?.id === store.currentUser?.id
  }

  get viewerIsAssessor() {
    return this.activity.record.assessor?.id === store.currentUser?.id
  }

  get name() {
    return this.viewerIsAuthor
      ? 'your'
      : `${this.activity.record.author?.fname}'s`
  }

  get checkinFinalised() {
    return this.activity.record.finalisedAt
  }

  get linkText() {
    if (this.activity.finalised) {
      return `View Check-in summary`
    } else if (this.activity.assessorApproved) {
      return `Start joint Check-in`
    } else {
      return this.viewerIsAuthor && !this.viewerIsAssessor
        ? 'Edit your Check-in'
        : `Start ${this.name} Check-in review`
    }
  }

  get link() {
    if (this.activity.finalised) {
      return `checkins/${this.activity.record.slug}`
    } else if (this.activity.assessorApproved) {
      return `checkins/${this.activity.record.slug}/review`
    } else {
      return `checkins/${this.activity.record.slug}/edit`
    }
  }

  get showLink() {
    return (
      store.currentUser &&
      this.creators.includes(store.currentUser) &&
      (this.activity.finalised ||
        (!this.checkinFinalised &&
          (this.activity.assessorApproved || this.viewerIsAssessor)))
    )
  }
}
