import { Reaction } from '../../store/modules/reactions'
import { User } from '../../store/modules/users'
import { store } from 'store/index'
export class ReactionBarVm {
  constructor(
    private reactions: Reaction[],
    private reactableId: string,
    private reactableType: string,
    private currentUser: User
  ) {}

  readonly addReactionTooltipMessage = 'Add reaction'

  readonly defaultReactions = ['🙌', '💚', '🥰', '👏', '➕', '👍']

  get uniqueReactions() {
    const reactions = new Map()
    // Loop over reactions and add to map if not already there, choosing
    // the earliest created one, so they don't 'jump' in the list

    this.reactions.forEach((reaction) => {
      if (!reactions.has(reaction.content)) {
        reactions.set(reaction.content, reaction)
      } else {
        const existingReaction = reactions.get(reaction.content)
        if (existingReaction.createdAt > reaction.createdAt) {
          reactions.set(reaction.content, reaction)
        }
      }
    })

    // Sort the reactions by earliest created
    return Array.from(reactions.values()).sort((a, b) => {
      return a.createdAt.toString().localeCompare(b.createdAt.toString())
    })
  }

  reactionTooltip(reaction: string) {
    const reactions = this.reactions.filter((r) => r.content === reaction)

    let tooltip = ''

    const youReacted = this.hasOwnReaction(reaction)

    if (reactions.length === 1) {
      if (youReacted) return 'You (click to remove)'
      else tooltip += `${reactions[0].creatorName}`
    } else if (reactions.length === 2) {
      if (youReacted) {
        const theirName = reactions.find(
          (r) => r.creatorId !== this.currentUser.id
        )?.creatorName
        return `You and ${theirName}`
      } else {
        tooltip += `${reactions[0].creatorName} and ${reactions[1].creatorName}`
      }
    } else {
      const peopleWithoutYou = reactions.filter(
        (r) => r.creatorId !== this.currentUser.id
      )
      const reactorNames = peopleWithoutYou.map((r) => r.creatorName)

      if (youReacted) {
        tooltip += `${reactorNames.join(', ')} and you`
      } else {
        const otherNames = reactorNames.slice(0, -1).join(', ')
        const lastName = [...reactorNames].pop()

        tooltip += `${otherNames} and ${lastName}`
      }
    }

    return tooltip
  }

  ownReaction(reaction: string) {
    return this.reactions.find(
      (r) => r.content === reaction && r.creatorId === this.currentUser.id
    )
  }

  hasOwnReaction(reaction: string) {
    return Boolean(this.ownReaction(reaction))
  }

  reactionCount(reaction: string) {
    return this.reactions.filter((r) => r.content === reaction).length
  }

  async onClickReaction(reaction: string) {
    const ownReaction = this.ownReaction(reaction)
    if (ownReaction) {
      this.removeReaction(ownReaction.id)
    } else {
      this.createReaction(reaction)
    }
  }

  private removeReaction(id: string) {
    const { reactions } = store
    reactions.destroy(id)
  }

  private createReaction(content: string) {
    const { reactions } = store
    reactions.create({
      reactable_id: this.reactableId,
      reactable_type: this.reactableType,
      content,
    })
  }
}
