import { Card } from 'src/design-system'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { SkillSelect } from 'components/skill-select'
import { SkillTags } from 'components/skill-tags'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'
import * as React from 'react'
import { FeedbackReceivedActivity } from '../../../store/modules/activities'
import { ActivityFeedHeader } from '../activity-feed-header'
import { FeedbackActivityItemVm } from '../activity-item-vm'

export type FeedbackActivityItemProps = {
  activity: FeedbackReceivedActivity
}

export const FeedbackActivityItem = observer<FeedbackActivityItemProps>(
  (props) => {
    const { activity } = props

    const vm = new FeedbackActivityItemVm(activity)

    return (
      <div className="flex w-full flex-col pb-10">
        <ActivityFeedHeader vm={vm} />
        <div className="ml-8">
          <Card className="m-0 gap-2">
            <HtmlContent>{vm.content}</HtmlContent>
            <div className="flex flex-row items-center">
              <SkillTags
                skills={vm.feedbackItem.skills}
                focusSkillIds={vm.focusSkillIds}
                showLinks
                characterLimit={45}
              />
              {vm.allowUpdateSkills && (
                <>
                  {vm.anySkills && (
                    <span className="mx-3 text-theme-50 text-xs">&middot;</span>
                  )}
                  <SkillSelect
                    {...vm.skillSelectProps}
                    triggerContent={
                      <>
                        {vm.skillSelectLabel}
                        <Plus className="text-theme-50" weight="bold" />
                      </>
                    }
                  />
                </>
              )}
            </div>
          </Card>
        </div>
      </div>
    )
  }
)
