import * as React from 'react'
import {
  ConfirmationDialog,
  OverflowMenu,
  Tooltip,
  useModalContext,
} from 'src/design-system'
import { store } from 'store/index'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { CreateFormProps } from 'components/create-form'
import { closeModal } from '../../utils/close-modal'
import { observer } from 'mobx-react-lite'
import { useStore } from 'store/context'
import { successToast } from '../../utils/success-toast'
import { errorToast } from '../../utils/error-toast'

export type WinOverflowMenuProps = {
  allowDelete: boolean
  allowEdit: boolean
  winId: string
  winCategory: 'Win' | 'Note'
}

export const WinOverflowMenu: React.VFC<WinOverflowMenuProps> = observer(
  (props) => {
    const { allowDelete, allowEdit, winId, winCategory } = props

    const { openModal } = useModalContext()

    const { wins } = useStore()
    const win = wins.byId(winId)

    const isDisabled = !allowDelete || !allowEdit

    const onClickEdit = React.useCallback(() => {
      if (!store.currentUser) return
      openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
        resourceId: winId,
        resourceType: winCategory,
        source: 'WinOverflowMenu',
        user: store.currentUser,
        tabs: winCategory === 'Win' ? ['win'] : ['note'],
      })
      closeModal()
    }, [winId, openModal, winCategory])

    const onConfirm = React.useCallback(async () => {
      const result = await win?.destroy()
      if (result?.success) {
        successToast(`${winCategory} deleted!`)
      } else {
        errorToast()
      }
    }, [win, winCategory])

    const onClickDelete = React.useCallback(() => {
      closeModal()
    }, [])

    if (!win) return null

    return (
      <ConfirmationDialog.Root
        body={`This will permanently remove this ${winCategory} from Progression.`}
        onConfirm={onConfirm}
        title={`Are you sure you want to delete this ${winCategory}?`}
      >
        <OverflowMenu.Root right contentClassName="min-w-[160px]">
          <Tooltip
            content={`Can only be edited and deleted by ${win.winCategory?.title} author`}
            disabled={!isDisabled}
          >
            <OverflowMenu.Button disabled={isDisabled} onClick={onClickEdit}>
              Edit
            </OverflowMenu.Button>
            <ConfirmationDialog.Trigger disabled={isDisabled} asChild>
              <OverflowMenu.Button
                onClick={onClickDelete}
                variant={!isDisabled ? 'destructive' : 'default'}
              >
                Delete
              </OverflowMenu.Button>
            </ConfirmationDialog.Trigger>
          </Tooltip>
        </OverflowMenu.Root>
      </ConfirmationDialog.Root>
    )
  }
)
