import { z } from 'zod'

export const reactionSchema = z.object({
  id: z.string(),
  content: z.string(),
  creatorName: z.string(),
  creatorId: z.string(),
  createdAt: z.preprocess((a) => new Date(z.string().parse(a)), z.date()),
  reactableId: z.string(),
  reactableType: z.string(),
  updatedAt: z.preprocess((a) => new Date(z.string().parse(a)), z.date()),
})

export const reactionsSchema = z.array(reactionSchema)

export type Reaction = z.infer<typeof reactionSchema>
