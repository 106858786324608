import { WinCreatedActivity } from '../../../store/modules/activities'
import { activityIconMap } from '../icon-map'
import { ActivityItemVm } from './activity-item-vm'

export class WinActivityItemVm extends ActivityItemVm<WinCreatedActivity> {
  content = this.activity.record.content
  reactions = this.activity.record.reactions
  receivers = this.activity.record.winners
  recordId = this.activity.record.id
  skills = this.activity.record.skills

  get icon() {
    return activityIconMap[this.title]
  }

  get title() {
    return this.activity.record.winCategory?.title || 'Win'
  }
}
