import { Tooltip } from 'src/design-system'
import * as React from 'react'
import { ReactionBarVm } from './reaction-bar-vm'
import cn from 'classnames'

type ReactionItemProps = {
  reaction: string
  vm: ReactionBarVm
}

export const ReactionItem: React.VFC<ReactionItemProps> = (props) => {
  const { reaction, vm } = props

  const isOwnReaction = vm.hasOwnReaction(reaction)

  const Tag = isOwnReaction ? 'button' : 'span'

  return (
    <Tooltip content={vm.reactionTooltip(reaction)}>
      <Tag
        className={cn(
          'rounded-xl py-0.5 px-2 text-gray-900 text-xs border border-solid select-none',
          isOwnReaction
            ? 'bg-theme-95 !border-theme-90'
            : 'bg-gray-50 !border-transparent'
        )}
        onClick={(e) => {
          e.stopPropagation()
          vm.onClickReaction(reaction)
        }}
      >
        {reaction}
        <span className="text-xs ml-1">{vm.reactionCount(reaction)}</span>
      </Tag>
    </Tooltip>
  )
}
