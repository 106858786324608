import { store } from 'store/index'
import { ActivityItemVm } from './activity-item-vm'
import { FeedbackRequestedActivity } from 'store/modules/activities'

export class FeedbackRequestActivityItemVm extends ActivityItemVm<FeedbackRequestedActivity> {
  content = this.activity.record.content
  receivers = this.activity.record.users
  creator = this.activity.creator

  get title() {
    return 'Requested Feedback'
  }

  get feedbackRequest() {
    return this.activity.record
  }

  get feedbackItems() {
    return store.feedbackItems.filtered((item) => {
      return item.feedbackRequest === this.feedbackRequest
    })
  }

  feedbackItemAuthoredBy(userId: string) {
    return store.feedbackItems.filtered((item) => {
      return (
        item.feedbackRequest === this.feedbackRequest &&
        item.author.id === userId
      )
    })[0]
  }

  get awaitingResponseCount() {
    return this.receivers.length - this.feedbackItems.length
  }

  get userIsRequester() {
    return store.currentUser?.id === this.creator.id
  }
}
