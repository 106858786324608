import { ActionCompletedActivity } from 'store/modules/activities'
import { ActivityItemVm } from './activity-item-vm'

export class ActionActivityItemVm extends ActivityItemVm<ActionCompletedActivity> {
  content = this.activity.record.content
  recordId = parseInt(this.activity.record.id)
  skills = this.activity.record.skills

  get title() {
    return 'Completed Action'
  }
}
